import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  heading: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CompassController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  // is this an IPad ?
  isiPad = this.isPlatformWeb() && navigator.userAgent.match(/iPad/i) != null;

  // is this an iPhone ?
  isiPhone =
    this.isPlatformWeb() && navigator?.userAgent?.match(/iPhone/i) != null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      heading: 0,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  startCompass() {
    if (this.isiPad || this.isiPhone) {
      //@ts-ignore
      DeviceOrientationEvent.requestPermission()
        .then((response: any) => {
          if (response === "granted") {
            this.subscribeToDeviceOrientation();
          } else {
            this.showAlert("Error", "Permission has been denied.");
          }
        })
        .catch(() => this.showAlert("Error", "Not supported."));
    } else {
      this.subscribeToDeviceOrientation();
    }
  }

  subscribeToDeviceOrientation() {
    if (this.isiPad || this.isiPhone) {
      window?.addEventListener(
        "deviceorientation",
        (e: any) =>
          this.setState({
            heading: e.webkitCompassHeading || Math.abs(e.alpha - 360),
          }),
        true
      );
    } else {
      window?.addEventListener(
        "deviceorientationabsolute",
        (e: any) =>
          this.setState({
            heading: e.webkitCompassHeading || Math.abs(e.alpha - 360),
          }),
        true
      );
    }
  }
  // Customizable Area End
}
