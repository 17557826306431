// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import FacialRecognition61 from "../../blocks/FacialRecognition61/src/FacialRecognition61";
import WeatherIntegration from "../../blocks/WeatherIntegration/src/WeatherIntegration";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import CallRecording2 from "../../blocks/CallRecording2/src/CallRecording2";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import TextDetector from "../../blocks/TextDetector/src/TextDetector";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import TextComparison from "../../blocks/textcomparison/src/TextComparison";
import BreadcrumbNavigation from "../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigation";
import OfflineBrowsing from "../../blocks/OfflineBrowsing/src/OfflineBrowsing";
import Polling from "../../blocks/Polling/src/Polling";
import VoipCalls from "../../blocks/VoipCalls/src/VoipCalls";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Maps from "../../blocks/maps/src/Maps";
import Emojis from "../../blocks/emojis/src/Emojis";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import CalculateRoyalties from "../../blocks/CalculateRoyalties/src/CalculateRoyalties";
import GoogleContactsImport from "../../blocks/googlecontactsimport/src/GoogleContactsImport";
import TimeZoneDetection from "../../blocks/timezonedetection/src/TimeZoneDetection";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import GoogleSitemapGenerator from "../../blocks/GoogleSitemapGenerator/src/GoogleSitemapGenerator";
import LikeAPost from "../../blocks/LikeAPost/src/LikeAPost";
import AdServing from "../../blocks/AdServing/src/AdServing";
import Animoji from "../../blocks/Animoji/src/Animoji";
import VisualSearch from "../../blocks/VisualSearch/src/VisualSearch";
import Videos from "../../blocks/videos/src/Videos";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import QrCodes from "../../blocks/qrcodes/src/QrCodes";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Geofence from "../../blocks/geofence/src/Geofence";
import Customform from "../../blocks/customform/src/Customform";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import RandomNumberGenerator from "../../blocks/randomnumbergenerator/src/RandomNumberGenerator";
import GooglePayIntegration from "../../blocks/GooglePayIntegration/src/GooglePayIntegration";
import ContactSharing from "../../blocks/ContactSharing/src/ContactSharing";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import PasscodeLock from "../../blocks/passcodelock/src/PasscodeLock";
import AppleStylusIntegration2 from "../../blocks/AppleStylusIntegration2/src/AppleStylusIntegration2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import SpellCheck from "../../blocks/SpellCheck/src/SpellCheck";
import Watermark from "../../blocks/Watermark/src/Watermark";
import Compass from "../../blocks/compass/src/Compass";
import PasswordStrength from "../../blocks/passwordstrength/src/PasswordStrength";
import Analytics from "../../blocks/analytics/src/Analytics";
import Cobrowsing from "../../blocks/cobrowsing/src/Cobrowsing";
import AmazonPayIntegration from "../../blocks/AmazonPayIntegration/src/AmazonPayIntegration";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import PdfEdit from "../../blocks/pdfedit/src/PdfEdit";
import CharacterCount from "../../blocks/charactercount/src/CharacterCount";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import VideoConversation from "../../blocks/VideoConversation/src/VideoConversation";
import AutomaticRenewals from "../../blocks/automaticrenewals/src/AutomaticRenewals";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import WordLookup from "../../blocks/WordLookup/src/WordLookup";
import GoogleNewsIntegration from "../../blocks/googlenewsintegration/src/GoogleNewsIntegration";
import VideoTrimming from "../../blocks/VideoTrimming/src/VideoTrimming";
import Captcha from "../../blocks/captcha/src/Captcha";
import TwitterFeedsDisplay from "../../blocks/twitterfeedsdisplay/src/TwitterFeedsDisplay";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Share from "../../blocks/share/src/Share";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import WorldClock from "../../blocks/worldclock/src/WorldClock";
import ContactsList from "../../blocks/ContactsList/src/ContactsList";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import ZipcodesSearch from "../../blocks/ZipcodesSearch/src/ZipcodesSearch";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Sorting from "../../blocks/sorting/src/Sorting";
import Location from "../../blocks/location/src/Location";
import MultipageForms2 from "../../blocks/MultipageForms2/src/MultipageForms2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import GettyImagesIntegration from "../../blocks/GettyImagesIntegration/src/GettyImagesIntegration";
import HomeControlOptions from "../../blocks/HomeControlOptions/src/HomeControlOptions";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import HamburgerMenu from "../../blocks/HamburgerMenu/src/HamburgerMenu";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Stopwatch from "../../blocks/stopwatch/src/Stopwatch";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import AdManager from "../../blocks/AdManager/src/AdManager";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import BeeFreeIntegration2 from "../../blocks/BeeFreeIntegration2/src/BeeFreeIntegration2";
import YoutubeIntegration2 from "../../blocks/YoutubeIntegration2/src/YoutubeIntegration2";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Search from "../../blocks/search/src/Search";
import CustomKeyboard2 from "../../blocks/CustomKeyboard2/src/CustomKeyboard2";
import CreateComment from "../../blocks/comments/src/CreateComment";
import InvertColour2 from "../../blocks/InvertColour2/src/InvertColour2";
import AlexaIntegration from "../../blocks/AlexaIntegration/src/AlexaIntegration";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
FacialRecognition61:{
 component:FacialRecognition61,
path:"/FacialRecognition61"},
WeatherIntegration:{
 component:WeatherIntegration,
path:"/WeatherIntegration"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
CallRecording2:{
 component:CallRecording2,
path:"/CallRecording2"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
TextDetector:{
 component:TextDetector,
path:"/TextDetector"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
MultipleCurrencySupport:{
 component:MultipleCurrencySupport,
path:"/MultipleCurrencySupport"},
TextComparison:{
 component:TextComparison,
path:"/TextComparison"},
BreadcrumbNavigation:{
 component:BreadcrumbNavigation,
path:"/BreadcrumbNavigation"},
OfflineBrowsing:{
 component:OfflineBrowsing,
path:"/OfflineBrowsing"},
Polling:{
 component:Polling,
path:"/Polling"},
VoipCalls:{
 component:VoipCalls,
path:"/VoipCalls"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Maps:{
 component:Maps,
path:"/Maps"},
Emojis:{
 component:Emojis,
path:"/Emojis"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
CalculateRoyalties:{
 component:CalculateRoyalties,
path:"/CalculateRoyalties"},
GoogleContactsImport:{
 component:GoogleContactsImport,
path:"/GoogleContactsImport"},
TimeZoneDetection:{
 component:TimeZoneDetection,
path:"/TimeZoneDetection"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
GoogleSitemapGenerator:{
 component:GoogleSitemapGenerator,
path:"/GoogleSitemapGenerator"},
LikeAPost:{
 component:LikeAPost,
path:"/LikeAPost"},
AdServing:{
 component:AdServing,
path:"/AdServing"},
Animoji:{
 component:Animoji,
path:"/Animoji"},
VisualSearch:{
 component:VisualSearch,
path:"/VisualSearch"},
Videos:{
 component:Videos,
path:"/Videos"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
QrCodes:{
 component:QrCodes,
path:"/QrCodes"},
Couponcodegenerator:{
 component:Couponcodegenerator,
path:"/Couponcodegenerator"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
Customform:{
 component:Customform,
path:"/Customform"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
RandomNumberGenerator:{
 component:RandomNumberGenerator,
path:"/RandomNumberGenerator"},
GooglePayIntegration:{
 component:GooglePayIntegration,
path:"/GooglePayIntegration"},
ContactSharing:{
 component:ContactSharing,
path:"/ContactSharing"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
PasscodeLock:{
 component:PasscodeLock,
path:"/PasscodeLock"},
AppleStylusIntegration2:{
 component:AppleStylusIntegration2,
path:"/AppleStylusIntegration2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Addresses:{
 component:Addresses,
path:"/Addresses"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
SpellCheck:{
 component:SpellCheck,
path:"/SpellCheck"},
Watermark:{
 component:Watermark,
path:"/Watermark"},
Compass:{
 component:Compass,
path:"/Compass"},
PasswordStrength:{
 component:PasswordStrength,
path:"/PasswordStrength"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Cobrowsing:{
 component:Cobrowsing,
path:"/Cobrowsing"},
AmazonPayIntegration:{
 component:AmazonPayIntegration,
path:"/AmazonPayIntegration"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs"},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
PdfEdit:{
 component:PdfEdit,
path:"/PdfEdit"},
CharacterCount:{
 component:CharacterCount,
path:"/CharacterCount"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
VideoConversation:{
 component:VideoConversation,
path:"/VideoConversation"},
AutomaticRenewals:{
 component:AutomaticRenewals,
path:"/AutomaticRenewals"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
UserStatus:{
 component:UserStatus,
path:"/UserStatus"},
WordLookup:{
 component:WordLookup,
path:"/WordLookup"},
GoogleNewsIntegration:{
 component:GoogleNewsIntegration,
path:"/GoogleNewsIntegration"},
VideoTrimming:{
 component:VideoTrimming,
path:"/VideoTrimming"},
Captcha:{
 component:Captcha,
path:"/Captcha"},
TwitterFeedsDisplay:{
 component:TwitterFeedsDisplay,
path:"/TwitterFeedsDisplay"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Share:{
 component:Share,
path:"/Share"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
WorldClock:{
 component:WorldClock,
path:"/WorldClock"},
ContactsList:{
 component:ContactsList,
path:"/ContactsList"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
ZipcodesSearch:{
 component:ZipcodesSearch,
path:"/ZipcodesSearch"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Location:{
 component:Location,
path:"/Location"},
MultipageForms2:{
 component:MultipageForms2,
path:"/MultipageForms2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
GettyImagesIntegration:{
 component:GettyImagesIntegration,
path:"/GettyImagesIntegration"},
HomeControlOptions:{
component:ShoppingCartOrders,
path:"/HomeControlOptions"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
HamburgerMenu:{
 component:HamburgerMenu,
path:"/HamburgerMenu"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Stopwatch:{
 component:Stopwatch,
path:"/Stopwatch"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
AdManager:{
 component:AdManager,
path:"/AdManager"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
BeeFreeIntegration2:{
 component:BeeFreeIntegration2,
path:"/BeeFreeIntegration2"},
YoutubeIntegration2:{
 component:YoutubeIntegration2,
path:"/YoutubeIntegration2"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Search:{
 component:Search,
path:"/Search"},
CustomKeyboard2:{
 component:CustomKeyboard2,
path:"/CustomKeyboard2"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
InvertColour2:{
 component:InvertColour2,
path:"/InvertColour2"},
AlexaIntegration:{
 component:AlexaIntegration,
path:"/AlexaIntegration"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
