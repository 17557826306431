import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  correctPin: string;
  showRemoveButton: boolean;
  enteredPin: string;
  showCompletedButton: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PasscodeLockController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pinviewRef = React.createRef();
  pin: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      correctPin: "56771",
      showRemoveButton: false,
      showCompletedButton: false,
      enteredPin: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleButtonPress(key: any) {
    if (key === "custom_left") {
      //@ts-ignore
      this.pinviewRef.current.clear();
    }
    if (key === "custom_right") {
      if (this.state.correctPin === this.state.enteredPin) {
        this.showAlert("", "Correct Pin Entry Success");
      } else {
        this.showAlert("", "Incorrect Pin. Try Again.");
        //@ts-ignore
        this.pinviewRef.current.clearAll();
      }
    }
  }

  validatePin(enteredPin: string) {
    this.setState({
      enteredPin: enteredPin,
      showRemoveButton: enteredPin.length > 0,
      showCompletedButton: enteredPin.length === 5,
    });
  }

  setEnteredPin(value: any) {
    console.log("entered pin value::" + value);
    this.validatePin(value);
  }

  onClear = () => {
    if (this.pin) {
      //@ts-ignore
      this.pin.clear();
    }
  };

  onComplete = (value: any, index: any) => {
    console.log("on complete", value, this.state.correctPin, index);
    if (this.state.correctPin === value) {
      this.showAlert("", "Correct Pin Entry Success");
    } else {
      this.showAlert("", "Incorrect Pin. Try Again.");
    }
    this.onClear();
  };

  // Customizable Area End
}
