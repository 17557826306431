import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any;
  searchKey: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GoogleNewsIntegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNewsApiCallId: any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      searchKey: "",
      loading: true,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getNewsApiCallId != null &&
      this.getNewsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.articles) {
        this.setState({ data: responseJson.articles, loading: false });
      } else {
        this.showAlert("Error", "Error");
        this.setState({ data: [], loading: false });
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getNews();
    // Customizable Area End
  }

  // Customizable Area Start
  getNews = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNewsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}${configJSON.endPointTopHeading}?country=us&apiKey=${configJSON.apiKey}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNewsByQuery = (query: string) => {
    if (query.length === 0) {
      this.showAlert("Message", configJSON.emptyMsg);
      return;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNewsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.baseUrl}/everything?q=${query}&from=${moment(
        new Date(),
        "YYYY-MM-DD"
      )}&sortBy=popularity&apiKey=${configJSON.apiKey}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  loadInBrowser = (url: string) => {
    const { Linking } = require("react-native");
    Linking.openURL(url).catch((err: any) =>
      console.error("Couldn't load page", err)
    );
  };
  renderNews = (item: any) => {};
  // Customizable Area End
}
