import React from "react";

// Customizable Area Start
import { Container, Box } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Timeline } from "react-twitter-widgets";
// Customizable Area End

import TwitterFeedsDisplayController, {
  Props,
} from "./TwitterFeedsDisplayController";

export default class TwitterFeedsDisplay extends TwitterFeedsDisplayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container>
          <Box sx={webStyle.mainWrapper}>
            <Timeline
              dataSource={{
                sourceType: "profile",
                screenName: "twitter",
              }}
              options={{
                width: 800,
              }}
            />
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
};
// Customizable Area End
