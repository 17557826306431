Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "GoogleContactsImport";
exports.labelBodyText = "GoogleContactsImport Body";

exports.btnExampleTitle = "CLICK ME";

exports.contactList = "Contact Lists";
exports.totalContact = "Total Contact :";
exports.contactName = "Contact Name";
exports.contactNumber = "Contact Number"

exports.clientID = "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com";
exports.clientIDMobile = "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com";
exports.importGoogleContact = "Import Google Contact";
exports.scope = "https://www.googleapis.com/auth/contacts.readonly";
exports.scopeAlertMessage = "Please allow to access google contact! & Try again.";
exports.signOut = "Sign Out";
// Customizable Area End