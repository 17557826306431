Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "GoogleNewsIntegration";
exports.labelBodyText = "GoogleNewsIntegration Body";

exports.baseUrl = "https://newsapi.org/v2";
exports.endPointTopHeading = "/top-headlines";
exports.apiKey = "d4971f7d132041f5a4d7f201e71a5177";
exports.emptyMsg = "Please enter the text to search";
exports.googleNews = "Google News";
exports.searchPlaceholder = "Search";
exports.searchButton = "Search";
exports.dataNotFound = "Data not found!";
exports.loading = "Loading..."
// Customizable Area End