import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { googleIcon } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authDetails: any;
  contacts: any;
  isSingedIn: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GoogleContactsImportCommonController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      authDetails: null,
      contacts: {},
      isSingedIn: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  getContacts = (access_token: any) => {
    let api_key = "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4";
    fetch(
      `https://people.googleapis.com/v1/people/me/connections?personFields=names%2Caddresses%2CcoverPhotos%2CphoneNumbers%2CemailAddresses&key=${api_key}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((res: any) => {
        return res.json();
      })
      .then((res: any) => {
        console.log({ res });
        if (res?.error?.code === 403) {
          this.setState({ authDetails: null });
          this.showAlert("Message", configJSON.scopeAlertMessage);
        } else {
          this.setState({ contacts: res });
        }
      });
  };

  googleLoginSuccess = (res: any) => {
    console.log(res);
    this.setState({ authDetails: res });
    this.getContacts(res.accessToken);
  };

  googleIconProps = {
    source: googleIcon,
  };
  googleIconWebProps = {
    src: googleIcon,
  };
  // Customizable Area End
}
