import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// import { GoogleLogin, GoogleLogout } from 'react-google-login';
// Customizable Area End

import GoogleContactsImportController from "./GoogleContactsImportController.web";

import { Props, configJSON } from "./GoogleContactsImportCommonController";

export default class GoogleContactsImport extends GoogleContactsImportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  googleSignIn = async () => {
    gapi.auth2.getAuthInstance().then((auth2: any) => {
      auth2
        .signIn()
        .then(async (res: any) => {
          const user = res.getAuthResponse(true);
          //@ts-ignore
          this.setState({ authDetails: res });
          this.getContacts(user.access_token);
          console.log(user.access_token);
        })
        .catch((e: any) => {
          console.log("Error: ", e);
        });
    });
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          {!this.state.authDetails ? (
            <Box style={webStyle.googleBtnWrapper}>
              <Button
                style={webStyle.googleSigninButton}
                onClick={this.googleSignIn}
              >
                <img {...this.googleIconWebProps} style={webStyle.googleIcon} />
                {configJSON.importGoogleContact}
              </Button>
            </Box>
          ) : (
            <>
              <Box style={webStyle.mainWrapper}>
                <Typography variant="h6" style={{ flex: 1 }}>
                  {configJSON.contactList}
                </Typography>
                <Typography variant="h6" style={webStyle.totalContact}>
                  {configJSON.totalContact} {this.state.contacts?.totalPeople}
                </Typography>
              </Box>
              <TableContainer style={webStyle.tableContainer} component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: 10 }}>#</TableCell>
                      <TableCell>{configJSON.contactName}</TableCell>
                      <TableCell>{configJSON.contactNumber}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.contacts?.connections?.map(
                      (item: any, index: number) => {
                        console.log({ item });
                        return (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {item?.names?.length > 0 &&
                                item.names[0]?.displayName}
                            </TableCell>
                            <TableCell>
                              {item?.phoneNumbers?.length > 0 &&
                                item.phoneNumbers[0]?.value}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
    button: {
      textTransform: "none",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "30px",
    alignItems: "center",
  },
  totalContact: {
    fontSize: 14,
  },
  tableContainer: {
    marginTop: 25,
  },
  googleBtnWrapper: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 50,
  },
  googleSigninButton: {
    backgroundColor: "#fff",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.54)",
    boxShadow:
      "rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px",
    borderRadius: 2,
    border: "1px solid transparent",
    fontSize: 14,
    fontWeight: 500,
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  googleIcon: {
    height: 18,
    marginRight: 10,
  },
};
// Customizable Area End
