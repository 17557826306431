import React from "react";

// Customizable Area Start
import { Container, Box, Typography } from "@material-ui/core";
import Select from "react-select";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
  },
});
// Customizable Area End

import WorldClockController, {
  Props,
  configJSON,
} from "./WorldClockController";

export default class WorldClock extends WorldClockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <Box sx={webStyle.mainWrapper}>
            <Select
              data-test-id="dropdownCountry"
              label="Choose country"
              options={this.getCountriesList()}
              onChange={(data: any) => this.setSelectedCountry(data.value)}
            />

            <Select
              data-test-id="dropdownTimezone"
              label="Choose timezone"
              options={this.getTimezonesList()}
              onChange={(data: any) => this.setSelectedTimezone(data.value)}
            />

            <Select
              data-test-id="dropdownTimeFormat"
              label="Choose format"
              options={this.getFormatOptions()}
              onChange={(data: any) => this.setTwentyFourHour(data.value)}
            />

            <Typography variant="h6">
              {this.getCurrentTimeInTimezone()}
            </Typography>
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
