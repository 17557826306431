import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const countriesDB = require("countries-db");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedCountry: string;
  selectedTimezone: string;
  twentyFourHour: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WorldClockController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedCountry: "",
      selectedTimezone: "",
      twentyFourHour: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getCountriesList = () => {
    const countries = Object.values(countriesDB.getAllCountries());
    return countries.map((country: any) => ({
      value: country.id,
      label: country.name,
    }));
  };

  setSelectedCountry = (id: string) => {
    this.setState({ selectedCountry: id });
  };

  getTimezonesList = () => {
    const countries: Array<any> = Object.values(countriesDB.getAllCountries());
    const timezones = countries.find(
      (country: any) => country.id === this.state.selectedCountry
    )?.timezones;
    return timezones?.map((timezone: any) => ({
      value: timezone,
      label: timezone,
    }));
  };

  setSelectedTimezone = (id: string) => {
    this.setState({ selectedTimezone: id });
  };

  getFormatOptions = () => {
    return [
      {
        label: "12 Hour",
        value: false,
      },
      {
        label: "24 Hour",
        value: true,
      },
    ];
  };

  setTwentyFourHour = (value: boolean) => {
    this.setState({ twentyFourHour: value });
  };

  getCurrentTimeInTimezone = (): string => {
    const { selectedTimezone } = this.state;

    if (!selectedTimezone) {
      return "";
    }

    const time = new Date().toLocaleTimeString("en-US", {
      timeZone: selectedTimezone,
      hour12: !this.state.twentyFourHour,
    });

    return time;
  };
  // Customizable Area End
}
